import React from 'react';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import MarketLayout from 'web/components/market_layout';
import Panel from 'web/components/panel';

import AccountLayout from '../components/layout';
import SubscriptionSchedule from '../components/subscription_schedule';
import {reducer as subscriptionSchedulReducer} from '../components/subscription_schedule/duck';

const SchedulePage = () => {
  const subscriptionSchedule = useSelector((state) => state.subscriptionSchedule);
  return (
    <MarketLayout disableUpcomingOrdersBanner disableBasketDropdown>
      <Helmet>
        <title>Subscriptions | Good Eggs</title>
      </Helmet>

      <div className="schedule-page">
        <AccountLayout slug="schedule">
          <Panel header="Subscriptions Schedule">
            <div className="schedule-page__section">
              {!subscriptionSchedule || !subscriptionSchedule.deliveries.length ? (
                <div>You have no subscriptions. Shop the market and subscribe at checkout.</div>
              ) : (
                <SubscriptionSchedule editable />
              )}
            </div>
          </Panel>
        </AccountLayout>
      </div>
    </MarketLayout>
  );
};

SchedulePage.pageName = 'Schedule';

SchedulePage.reducer = function (state, action) {
  if (!state) throw new Error('State should always be preloaded here');

  const newState = MarketLayout.reducer(state, action);
  const subscriptionSchedule = subscriptionSchedulReducer(newState.subscriptionSchedule, action);
  return {...newState, subscriptionSchedule};
};

SchedulePage.propTypes = {
  subscriptionSchedule: PropTypes.shape({
    deliveries: PropTypes.array.isRequired,
  }),
};

export default SchedulePage;
