import update from 'immutability-helper';

import {actions as marketLayoutActions} from 'web/components/market_layout/actions';

import {
  postSubscriptionSkip,
  postSubscriptionUnskip,
  postSubscriptionCancel,
} from '../../helpers/api_client';

export const SKIP = 'SUBSCRIPTION_SCHEDULE_SKIP';
export const UNSKIP = 'SUBSCRIPTION_SCHEDULE_UNSKIP';
export const SKIP_CHANGE_COMPLETE = 'SUBSCRIPTION_SCHEDULE_SKIP_CHANGE_COMPLETE';
export const ERROR = 'SUBSCRIPTION_SCHEDULE_ERROR';
export const CANCEL_SUBSCRIPTION = 'SUBSCRIPTION_CANCEL';
export const CONFIRM_CANCEL_SUBSCRIPTION = 'SUBSCRIPTION_CONFIRM_CANCEL';
export const UNCONFIRM_CANCEL_SUBSCRIPTION = 'SUBSCRIPTION_UNCONFIRM_CANCEL';

export const actions = {
  skip: (subscriptionId, day) =>
    function (dispatch) {
      dispatch({type: SKIP, subscriptionId, day});

      postSubscriptionSkip(subscriptionId, day)
        .then((delivery) => dispatch({type: SKIP_CHANGE_COMPLETE, delivery}))
        .catch(function (error) {
          dispatch({type: UNSKIP, subscriptionId, day});
          dispatch({type: ERROR, error});
        });
    },

  unskip: (subscriptionId, day) =>
    function (dispatch) {
      dispatch({type: UNSKIP, subscriptionId, day});

      postSubscriptionUnskip(subscriptionId, day)
        .then((delivery) => dispatch({type: SKIP_CHANGE_COMPLETE, delivery}))
        .catch(function (error) {
          dispatch({type: SKIP, subscriptionId, day});
          dispatch({type: ERROR, error});
        });
    },

  confirmCancel: (id) =>
    function (dispatch) {
      dispatch(marketLayoutActions.disableScrolling());
      dispatch({type: CONFIRM_CANCEL_SUBSCRIPTION, id});
    },

  unconfirmCancel: () =>
    function (dispatch) {
      dispatch(marketLayoutActions.enableScrolling());
      dispatch({type: UNCONFIRM_CANCEL_SUBSCRIPTION});
    },

  cancel: (id) =>
    function (dispatch) {
      postSubscriptionCancel(id)
        .then((response) => {
          dispatch(marketLayoutActions.enableScrolling());
          dispatch({type: CANCEL_SUBSCRIPTION, updatedDeliveries: response.deliveries});
        })
        .catch(function (error) {
          dispatch(marketLayoutActions.enableScrolling());
          dispatch({type: UNCONFIRM_CANCEL_SUBSCRIPTION});
          dispatch({type: ERROR, error});
        });
    },
};

function findDeliveryIndex(deliveries, subscriptionId, day) {
  for (let i = 0; i < deliveries.length; i++) {
    const delivery = deliveries[i];
    if (delivery.subscriptionId === subscriptionId && delivery.day === day) {
      return i;
    }
  }

  return undefined;
}

function updateDeliveryStatus(state, subscriptionId, day, status) {
  const index = findDeliveryIndex(state.deliveries, subscriptionId, day);
  if (index === undefined) {
    return state;
  }

  return update(state, {deliveries: {[index]: {status: {$set: status}}}});
}

function updateDelivery(state, delivery) {
  const index = findDeliveryIndex(state.deliveries, delivery.subscriptionId, delivery.day);
  if (index === undefined) {
    return state;
  }

  return update(state, {deliveries: {[index]: {$set: delivery}}});
}

export function reducer(state = {}, action = {}) {
  switch (action.type) {
    case SKIP:
      state = update(state, {$unset: ['error']});
      return updateDeliveryStatus(state, action.subscriptionId, action.day, 'skipped');
    case UNSKIP:
      state = update(state, {$unset: ['error']});
      return updateDeliveryStatus(state, action.subscriptionId, action.day, 'scheduled');
    case SKIP_CHANGE_COMPLETE:
      return updateDelivery(state, action.delivery);
    case CONFIRM_CANCEL_SUBSCRIPTION:
      return update(state, {isConfirming: {$set: true}, cancellingSubscription: {$set: action.id}});
    case UNCONFIRM_CANCEL_SUBSCRIPTION:
      return update(state, {isConfirming: {$set: false}}, {$unset: ['cancellingSubscription']});
    case CANCEL_SUBSCRIPTION:
      state = update(state, {isConfirming: {$set: false}}, {$unset: ['cancellingSubscription']});
      state = {...state, deliveries: action.updatedDeliveries};
      return state;
    case ERROR:
      return update(state, {error: {$set: action.error}});
    default:
  }
  return state;
}
